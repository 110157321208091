import classnames from 'classnames';
import React from 'react';

const gridClasses = {
  xs: {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
    12: 'grid-cols-12',
  },
  sm: {
    1: 'sm:grid-cols-1',
    2: 'sm:grid-cols-2',
    3: 'sm:grid-cols-3',
    4: 'sm:grid-cols-4',
    5: 'sm:grid-cols-5',
    6: 'sm:grid-cols-6',
    12: 'sm:grid-cols-12',
  },
  md: {
    1: 'md:grid-cols-1',
    2: 'md:grid-cols-2',
    3: 'md:grid-cols-3',
    4: 'md:grid-cols-4',
    5: 'md:grid-cols-5',
    6: 'md:grid-cols-6',
    12: 'md:grid-cols-12',
  },
  lg: {
    1: 'lg:grid-cols-1',
    2: 'lg:grid-cols-2',
    3: 'lg:grid-cols-3',
    4: 'lg:grid-cols-4',
    5: 'lg:grid-cols-5',
    6: 'lg:grid-cols-6',
    12: 'lg:grid-cols-12',
  },
  xl: {
    1: 'xl:grid-cols-1',
    2: 'xl:grid-cols-2',
    3: 'xl:grid-cols-3',
    4: 'xl:grid-cols-4',
    5: 'xl:grid-cols-5',
    6: 'xl:grid-cols-6',
    12: 'xl:grid-cols-12',
  },
};

const getClassNamesForBreakpoint = (
  prefix,
  cols,
) => {
  if (cols) {
    return gridClasses[prefix][cols];
  }

  return null;
};

const Row = ({
  children,
  xs = 1,
  sm,
  md,
  lg,
  xl,
  noGap,
  className,
  as = 'div',
}) => {
  const Component = as || 'div';

  return (
    <Component
      className={classnames(
        className,
        'grid',
        getClassNamesForBreakpoint('xs', xs),
        getClassNamesForBreakpoint('sm', sm),
        getClassNamesForBreakpoint('md', md),
        getClassNamesForBreakpoint('lg', lg),
        getClassNamesForBreakpoint('xl', xl),
        {
          'gap-x-8': !noGap,
        }
      )}
    >
      {children}
    </Component>
  );
};

export default Row;
