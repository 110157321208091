import * as React from 'react';
import Col from '../components/Grid/Col';
import Row from '../components/Grid/Row';
import Link from '../components/Link';
import LogoFull from '../components/LogoFull';
import Typography from '../components/Typography';

const Footer = () => {
  return (
    <footer>
      <div className="container-xl py-16">
        <Row lg={2} className="items-end">
          <Col>
            <LogoFull className="h-14 max-w-full" />
            <span className="sr-only">Музей Миколи Помоненка</span>
          </Col>
          <Col className="flex-shrink space-y-2 lg:text-right mt-12">
            <Typography variant="small" className="hidden lg:block">
              за підтримки проєкту
              <br />
              <Link to="https://folklore.com.ua/">
                “Перлини української народної творчості”
              </Link>
            </Typography>
            <Typography variant="small">
              <Link to="https://pratsko.dev/">
                розробка вебсайту
              </Link>
              {' '}
              <Link to="https://pratsko.dev/">
                pratsko.dev
              </Link>
            </Typography>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
