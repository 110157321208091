import * as React from 'react';

const Header = () => {
  return (
    <>
      <header className="bg-gray-800">

      </header>
    </>
  );
};

export default Header;
