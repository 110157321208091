/* eslint-disable react/jsx-props-no-spreading */
import classnames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import isInternalLink from '../../utils/isInternalLink';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.node,
  partiallyActive: PropTypes.bool,
  to: PropTypes.string.isRequired,
  isButton: PropTypes.bool,
};

const defaultProps = {
  activeClassName: null,
  children: null,
  className: null,
  partiallyActive: false,
  isButton: false,
};

const FILE_LINK_PATTERN = /\.[0-9a-z]+$/i;
const UNDERLINE_CLASS_PATTERN = /(^|\s)underline-\S+/;
const DECORATION_CLASS_PATTERN = /(^|\s)decoration-((?!(slice|clone))\S)+/;

const Link = ({
    activeClassName,
    children,
    className,
    partiallyActive,
    to,
    isButton,
    ...rest
}) => {
    const classNames = classnames(
      className,
      {
        underline: !(className && className.includes('no-underline')),
        'decoration-font': !DECORATION_CLASS_PATTERN.test(className),
        'unreline': !UNDERLINE_CLASS_PATTERN.test(className),
        'underline-offset-4 decoration-1 hover:opacity-50 duration-200': !isButton,
      },
    );

    if (!isInternalLink(to)) {
      return (
        <a href={to} target="_blank" rel="noopener noreferrer" className={classNames} {...rest}>
          {children}
        </a>
      );
    }

    if (FILE_LINK_PATTERN.test(to)) {
      return (
        <a href={to} className={classNames} {...rest}>
          {children}
        </a>
      );
    }

    const linkWithoutHost = to.includes('muzey-pimonenko.org') ? to.split('muzey-pimonenko.org')[1] : to;

    return (
      <GatsbyLink
          to={linkWithoutHost}
          activeClassName={activeClassName}
          className={classNames}
          partiallyActive={partiallyActive}
          {...rest}
      >
        {children}
      </GatsbyLink>
    );
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
