import classnames from 'classnames';
import React from 'react';

const variantMapping = {
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  heading4: 'h4',
  heading5: 'h5',
  heading6: 'h6',
  blockquote: 'p',
  body: 'p',
  intro: 'p',
  small: 'p',
};

const styleMapping = {
  heading1: 'typo-h1',
  heading2: 'typo-h2',
  heading3: 'typo-h3',
  heading4: 'typo-h4',
  heading5: 'typo-h5',
  heading6: 'typo-h6',
  blockquote: 'typo-blockquote',
  body: 'typo-body',
  intro: 'typo-intro',
  small: 'typo-small',
};

const Typography = ({
  as,
  children,
  className,
  id,
  variant = 'body',
  ...props
}) => {
  const Component = as || variantMapping[variant];

  return (
    <Component
      id={id}
      className={classnames(styleMapping[variant], className)}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Typography;
